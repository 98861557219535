export enum FaqEntryType {
  NoAccessToEmail = 'HOW-DO-I-CHANGE-OR-CONFIRM-MY-EMAIL',
  NoAccessToOldEmail = 'HOW_TO_CHANGE_EMAIL_WITHOUT_ACCESS_TO_OLD_EMAIL',
  SmsVerfication = 'SMS-VERIFICATION',
  PhoneVerification = 'PHONE-VERIFICATION',
  PhoneChange = 'PHONE-CHANGE',
  PendingBalance = 'WHAT-DOES-PENDING-PAYMENT-MEAN',
  PaymentFailed = 'PAYMENT-FAILED-TX',
  Donations = 'DONATION',
  KycVerificationProblems = 'KYC-VERIFICATION-PROBLEMS',
  PortalMerge = 'LT-MERGER-DAY-COMMS',
  MakingGoodPhoto = 'MAKING-A-GOOD-PHOTO',
  BusinessRow = 'B2C-ROW',
  InsufficientBalance = 'FMR-CONTACT',
  RequestDataExport = 'REQUEST-DATA-EXPORT',
  Bump = 'HOW-DOES-BUMPS-WORK',
  ClosetPromotion = 'HOW-DOES-CLOSET-PROMO-WORK',
  CrossCurrency = 'CROSS-CURRENCY',
  SetCorrectPrice = 'HOW-TO-PRICE',
  WriteGoodItemDescription = 'HOW-TO-WRITE-A-GOOD-ITEM-DESCRIPTION',
  OfflineVerification = 'HAOV-ITEM-VERIFICATION-SELLING',
  ElectronicsVerificationSeller = 'ELECTRONIC-VERIFICATION-SELLING',
  SpamPhishing = 'SPAM_PHISHING',
  BlockingMember = 'HOW-DO-I-BLOCK-ANOTHER-MEMBER',
  VintedGuide = 'VINTED-GUIDE',
  HowToVerifyEmailCode = 'EMAIL-VERIFICATION',
  UploadingAnItem = 'UPLOADING-AN-ITEM',
  ShippingSeller = 'SHIPPING-SELLER',
  VintedWallet = 'VINTED-WALLET',
  TrackDelivery = 'TRACK-DELIVERY',
  IsItFreeToSell = 'IS-IT-FREE-TO-SELL',
  FindingAnItem = 'FINDING-AN-ITEM',
  BuyingStepByStep = 'BUYING-STEP-BY-STEP',
  ShippingBuyer = 'SHIPPING-BUYER',
  BuyerProtection = 'GUIDE-TO-BUYER-PROTECTION',
  IhaiRefundPolicy = 'IHAI-REFUND-POLICY',
  HelpCenterRoot = 'ROOT',
  MembersFeedback = 'MEMBERS-FEEDBACK',
  AddPhoneNumber = 'PHONE-VERIFICATION-NOT-WORKING',
  LeavingFeedbackForBuyer = 'LEAVING-FEEDBACK-FOR-BUYER',
  CantLogin = 'CANT-LOG-IN',
  Collection = 'VAS-FEATURED-COLLECTION',
  AccountStaff = 'PRO-ACCOUNT-SHARING',
  TaxpayerReportingMistakeSeller = 'DAC7-REPORTING-MISTAKE-SELLER',
  DsaLegalRequirements = 'DSA-B2C-OBLIGATIONS',
  Dac7Reporting = 'DAC7-REPORTING',
  HowToCompleteDac7Report = 'HOW-TO-COMPLETE-DAC7-REPORT',
  Dac7HarmReducation = 'DAC7-HARM-REDUCTION',
  Dac7SpecialVerification = 'DAC7-SPECIAL-VERIFICATION',
}

export enum PaidReturnFaqEntryId {
  WrongSize = 467,
  DamagedItem = 469,
  WrongItem = 470,
  WrongSizeReturn = 1044,
  DamagedItemReturn = 1046,
  WrongItemReturn = 1047,
}
